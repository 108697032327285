/**@jsx jsx */
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, Link } from "gatsby";
import { Embed, Heading, jsx, Text } from "theme-ui";
import Image from "gatsby-image";
import { Layout } from "../components/layout/main-layout";
import { useContext, useState } from "react";
import { AppContext } from "../context/app-context";
import { Seo } from "../components/seo/seo";
import { getDate } from "../utils/date";

export default function BlogPostTemplate({ data, pageContext }) {
  const { locale } = useContext(AppContext);
  const lang = pageContext.locale === "es-PE" ? "es" : "en";
  const [showId, setShowId] = useState([]);
  const [postId, setPostId] = useState(null);
  const {
    metaImage,
    title,
    childContentfulBlogContentRichTextNode,
    createdAt,
    showImage,
  } = data.contentfulBlog;
  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node) => {
        return (
          <Heading as="h3" sx={{ textTransform: "uppercase" }}>
            {node.content[0].value}
          </Heading>
        );
      },
      [BLOCKS.PARAGRAPH]: (node) => {
        const entry = node.content.find(
          (item) => item.nodeType === "embedded-entry-inline"
        );
        const hyperLink = node.content.find(
          (item) => item.nodeType === "hyperlink"
        );
        if (hyperLink) {
          if (hyperLink.data.uri.includes("youtube")) {
            const url = hyperLink.data.uri.split(
              "https://www.youtube.com/watch?v="
            )[1];
            const id = url.split("&")[0];
            return <Embed src={`https://www.youtube.com/embed/${id}`} />;
          }
        }
        if (entry) {
          const fields = entry.data.target.fields;
          const sys = entry.data.target.sys;
          const content =
            locale === "es-PE"
              ? fields.content["es-PE"]
              : fields.content["en-US"];
          const title =
            locale === "es-PE" ? fields.title["es-PE"] : fields.title["en-US"];
          return (
            <ul>
              <li
                onClick={() =>
                  setShowId((c) =>
                    c.includes(sys.contentful_id)
                      ? c.filter((id) => id !== sys.contentful_id)
                      : [...c, sys.contentful_id]
                  )
                }
              >
                <Heading
                  as="h4"
                  sx={{
                    textAlign: "left",
                    borderBottom: "1px solid gray",
                    cursor: "pointer",
                  }}
                >
                  {title} {showId.includes(sys.contentful_id) ? "-" : "+"}
                </Heading>
              </li>
              <div
                sx={{
                  visibility: showId.includes(sys.contentful_id)
                    ? "visible"
                    : "hidden",
                  height: showId.includes(sys.contentful_id) ? "auto" : 0,
                  opacity: showId.includes(sys.contentful_id) ? 1 : 0,
                  transition: "200ms linear",
                }}
              >
                {documentToReactComponents(content)}
              </div>
            </ul>
          );
        }

        return documentToReactComponents(node);
      },
    },
  };
  const description = documentToReactComponents(
    childContentfulBlogContentRichTextNode.json,
    options
  );

  const date = getDate(createdAt, pageContext.locale);
  const posts = data.allContentfulBlog.nodes;
  return (
    <Layout>
      <Seo
        title={title}
        image={
          metaImage &&
          metaImage.fluid && {
            src: metaImage.fluid.src,
            height: 300,
            width: 300,
          }
        }
        lang={lang}
      />
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", null, "800px 1fr"],
          maxWidth: "1280px",
          mx: "auto",
          my: [null, 5],
          gap: 4,
        }}
      >
        <div
          sx={{
            maxWidth: ["720px", null, null, "800px"],
            mx: "auto",
            px: [3, 5],
            py: 5,
            boxShadow: [
              null,
              "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
            ],
          }}
        >
          <div
            sx={{
              maxWidth: "960px",
              mx: "auto",
            }}
          >
            <Heading
              as="h1"
              sx={{
                textTransform: "uppercase",
                fontSize: 4,
                textAlign: "left",
                color: "header",
              }}
            >
              {title}
            </Heading>
            <Text sx={{ mb: 4 }}>{date}</Text>
            {showImage != false && <Image fluid={metaImage?.fluid} />}
            <div sx={{ px: 3 }}>{description}</div>
          </div>
        </div>
        <div>
          {posts.map((post) => (
            <Link
              to={`/${lang}/${pageContext.middlePath}/${post.slug}`}
              sx={{
                display: "grid",
                p: 3,
                gap: 3,
                mb: 3,
                boxShadow: [
                  null,
                  "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
                ],
                gridTemplateColumns: "1fr 1fr",
                bg: "footer",
                color: "text",
                borderRadius: "16px",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onMouseEnter={() => {
                setPostId(post.id);
              }}
              onMouseLeave={() => {
                setPostId(null);
              }}
              key={post.id}
            >
              <Image
                fluid={post.metaImage?.fluid}
                imgStyle={{
                  filter: postId !== post.id && "grayscale(100%)",
                  transition: "200ms",
                }}
              />
              <div
                sx={{
                  textAlign: "left",
                  display: "grid",
                  placeContent: "center",
                }}
              >
                <Heading
                  as="h3"
                  sx={{
                    height: "134px",
                    overflow: "hidden",
                    display: "block",
                    color: "header",
                  }}
                >
                  {post.title}
                </Heading>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulBlog(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      tag
      showImage
      metaImage {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      childContentfulBlogContentRichTextNode {
        json
      }
      contentful_id
      createdAt(locale: "es-PE")
    }
    allContentfulBlog(
      limit: 5
      sort: { fields: updatedAt, order: DESC }
      filter: { node_locale: { eq: "es-PE" }, slug: { ne: $slug } }
    ) {
      nodes {
        id
        title
        slug
        metaImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
